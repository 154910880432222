.Logo {
    height: 100%;
    width: 100px;
    color: white;
    padding: 0;
}

/* .Back {
    height: 400px;
    background-repeat: no-repeat;
    width: 100%;
    background-image: url(https://images.pexels.com/photos/290470/pexels-photo-290470.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
} */

.Navigation {
    
    background-color: rgb(0,0,0,.5);
    z-index: 499;
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.Navigation{
    padding-top: 0;
    padding-bottom: 0;
}

.Company {
    color: white;
    opacity: 1;
}

@media(max-width: 430px) {
    .Company {
        opacity: 0;
        display: none;
    }
}

