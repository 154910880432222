.Tipjar {
    height: '700px';
    
}

.eee {
    background-color: #eee;
}

.FixedButton {
    clip-path: circle(50% at 50% 50%);

    
}


.Rotator{
    width: 70%;
    height: 300px;
    padding: 0;
    display: block;
    margin: auto;
  
}

.Tip {
    background-color: orange;
    border-radius: 16px;
    margin-left: 5px;
    margin-top: 5px;
}

.Tip:hover {
    background-color: lightsalmon;
}

.Logo {
    height: 100px;
    width: 100px;
}

.Carou {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.7;
}

.Clip{
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 70% 80%, 30% 80%, 0 100%, 0 0);
}



/* .blackOverlay{
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: .4;
    background: rgba(0,0,0,0.6);
} */


.Testimonials {
    height: 100%;
    width: 100%;
    opacity: .6;
    
}

.background {
    background-color: black;
    position: relative;
    color: white;
    text-align: center;
}

@media(max-width: 500px) {
    .background{
        padding-top: 60px;
    }
    .centered {
        padding-top: 15px
    }
      
    
}

.centered {
    color: white;
    text-align: center;
    position: absolute;
    top: 92%;
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 95%;
    padding-bottom: 20px;
    font-size: 2.0em;
    
  }

  @media(min-width: 600px) {
      .centered {
          font-size: 3.0em;
          padding-bottom: 30px;
      }
  }

