.background{
    padding-top: 58px;
    text-align: left;
}

@media(max-width: 500px) {
    .background {
        text-align: center;
    }
}

.rose {
    background-color: orange;
    border-radius: 16px;
    margin-left: 5px;
    margin-top: 5px;
}

.rose:hover {
    background-color: lightsalmon;
}