/* img:hover {
    opacity: .5;
} */

@media(max-width: 750px) {
    .Card{
        width: 75%;
        margin: auto;
    }
   
}



.Card {
    /* background-color: #f8f9fa; */
    padding: 10px;
    /* padding-top: 70px; */
    border: 0;
    background-color: rgb(246,249,252);
    /* height: 100%; */
    overflow: hidden;
   
    border-radius: 32px;
    /* -webkit-transition: background-color 1000ms linear;
    -moz-transition: background-color 1000ms linear;
    -o-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear; */
    /* transition: all .25s cubic-bezier(.73,.39,.54,.82); */
    /* transition: background-color .2s ease-in;
    transition: background-color .4s ease-out; */
    /* box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5) */
}

.Card:Hover{
    background-color: white;
    /* background-image: linear-gradient(100deg, #29A9CC 100%, #2394C0 50%, #f8f9fa 0%); */
    /* background-image: linear-gradient(100deg, #f8f9fa 0%, #f8f9fa 100%); */
    /* background-size: 240% 100%;
    background-position: 100% 0%; */
   /* background-color: #4284b7; */
   border-radius: 32px;
   box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5);
   color: rgb(170, 27, 170);
   font-size: 1.15em;
   /* transition: all .25s cubic-bezier(.73,.39,.54,.82); */
   
}

.Icon{
    /* padding-top: 10px; */
    margin: 0;
    text-align: center;
    font-size: 2.5em;
    transition: all .25s cubic-bezier(.73,.39,.54,.82);
    padding-bottom: 5px;
    
    /* left: 43%;
    bottom: 75%; */
}

/* @media(max-width: 524px) {
    .Icon{
       
    }
   
}

@media(min-width: 525px) and (max-width: 755px) {
    .Icon{
        position: absolute;
        left: 47%;
        bottom: 65%;
    }
   
}

@media(min-width: 756px) and (max-width: 1100px) {
    .Icon{
        position: absolute;
        left: 41%;
        bottom: 80%;
    }
   
} */

/* .Icon:hover{
    color: rgb(170, 27, 170);
    font-size: 2.75em;
} */

.ServiceLink {
    color: #000000;
    text-decoration: none;
}

.ServiceLink:hover 
{
     
     text-decoration:none; 
       
}