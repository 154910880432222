body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(230,228,226); */
  background-color:  rgb(246,249,252);
}

code {
  font-family: 'Roboto', sans-serif;
}
