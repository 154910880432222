.CertBanner {
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
    box-shadow: 2px 3px #eee;
}

.CertBanner img{
    padding: 35px;
}

@media (min-width: 600px) {
    .CertBanner img {
       margin: auto 50px
    }
}