.background {
    padding-top: 60px;
    text-align: center;
}

.bapic {
    height: 250px;
    width: 90%
}

/* .Top {
    text-align: left;
    
} */

@media(max-width: 500px) {
    .background {
        text-align: center;
    }
}