/* .contain {
    height: 200px;
} */

.Testimonials {
    height: 100%;
    width: 100%;
    opacity: .4;
    

}

.background {
    background-color: #1a19198c;
    position: relative;
    color: white;
    text-align: center;
    /* clip-path: polygon(0 5%, 100% 25%, 100% 100%, 0% 100%); */



}

.centered {
    position: absolute;
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%);
    width: 95%;
  }

  @media (min-width: 1000px) {
      .centered {
          font-size: 2.0em;
      }
  }